{
    "about-us":"Hakkımızda",
    "services":"Hizmetlerimiz",
    "contact":"İletişim",
    "services-sub-1":"ERP Çözümleri",
    "services-sub-2":"BT Yönetimi",
    "services-sub-3":"BT Güvenlik Kavramları",
    "services-sub-4":"Sanallaştırma",
    "hero-title":"NGSI, BT konusunda profesyonel ortağınız.",
    "hero-content":"Deneyimli ve yenilikçi beyinlerden oluşan ekibimiz, tüm BT altyapınızı optimize eder. Nerede sıkıntı olduğu önemli değil, 360° görüşümüz sayesinde her zaman size uygun çözümümüz bulunmaktadır. Değerli zamanınızı BT sorunlarıyla boşa harcamayın. Siz çekirdek yeteneklerinize odaklanırken, biz BT'yi düzenleriz.",
    "hero":"serviceItemsEnglish",
    "link-button":"Devamı...",
    "middle-section-header":"NGSI ile büyüme zamanı",
    "middle-section-title":"Dynamics 365 Business Central - piyasada en güçlü ERP çözümlerinden biri",
    "middle-section-content":"En önemli iş süreçlerini optimize etme ve birleştirme. İş operasyonları ve stratejilerinizi değerlendirme, tüm proje aşamalarında takip, sorumluluk üstlenme ve iş süreci analizinden canlı destek sürecine kadar sorunsuz proje performansı garantisi sunuyoruz.",
    "explore-title":"Bizi ve çalışmalarımızı daha fazla öğrenmek ister misiniz?",
    "explore-content":"Bize ulaşın ve hizmetimizi kendiniz deneyimleyin.",
    "explore-phone":"HEMEN ARA!",
    "references":"Çözüm Ortaklarımız",
    "contact-form-title":"İletişim Formu",
    "contact-form-sub-title":"Sizinle konuşmaktan memnuniyet duyarız",
    "contact-form-full-name":"Adınız Soyadınız",
    "contact-form-email-address":"E-posta adresi",
    "contact-form-subject":"Konu",
    "contact-form-message":"Mesaj",
    "contact-form-submit-button":"İletişime Geçin",
    "contact-side-follow-us":"Bizi Takip Edin",
    "contact-side-title":"Mükemmel ERP Çözümü",
    "contact-side-desctription":"Business Central'da ERP geliştirme, küçük ve orta ölçekli işletmeler için hepsi bir arada bir iş yönetimi çözümü olan Microsoft Dynamics 365 Business Central'ın işlevselliğinin özelleştirilmesi ve genişletilmesi anlamına gelir.",
    "footer-about-us":"BT'nin iş başarısı için her zamankinden daha önemli olduğu bir zamanda, sektörden bağımsız olarak gereksinimler giderek daha karmaşık hale geliyor. Stuttgart yakınlarındaki Weissach'ta bulunan NGSI - NextGen Solution & Innovation, tüm BT ihtiyaçlarınız için ortağınızdır.",
    "about-us-main-title":"Onlarca yıllık uzmanlığımızdan yararlanın!",
    "about-us-main-card-first-title": "Bakıyoruz,",
    "about-us-main-card-first-description": "tam olarak neresi çalışması gerektiği gibi çalışmıyor. Mevcut sistemler analiz edildikten sonra, açıklar, zayıflıklar belirlenir ve bir durum tespiti yapılır. İlk konsept hazır olduğunda, tekrar kapınızı çalıyoruz.",
    "about-us-main-card-second-title": "... tartışıyoruz",
    "about-us-main-card-second-description": "sizin için hangi çözümlerin uygun olduğunu sizinle birlikte değerlendiriyoruz. İhtiyaçlarınıza ve isteklerinize göre, zaman çizelgesini birlikte oluşturuyoruz, böylece hiçbir detay açıkta kalmaz - elbette belirlediğiniz bütçeye göre.",
    "about-us-main-card-third-title": "ve başlıyoruz!",
    "about-us-main-card-third-description": "Kararlaştırılan bireysel noktaları gerçekleştiriyoruz, düzenli olarak iletişimde kalıyoruz ve projenin tamamlanmasından sonra bile destek sağlıyoruz, böylece artan üretkenlik ve düşen BT maliyetlerinin keyfini sürebilirsiniz!",
    "about-us-sub-main-title": "Keşfedin",
    "about-us-sub-first-title": "BT Çözümlerinden Daha Fazlası",
    "about-us-sub-first-decription": "Endüstriden bağımsız olarak, BT'nin operasyonel başarı için her zamankinden daha kritik olduğu bir dönemde, gereksinimler giderek daha karmaşık hale geliyor.",
    "about-us-sub-first-decription-2": "NGSI - NextGen Solution & Innovation, Stuttgart yakınlarındaki Weissach merkezli tüm BT ihtiyaçlarınız için iş ortağınızdır. Planlamadan, satın almaya, projenizin gerçekleştirilmesinden işletmeye ve tabii ki ötesine kadar size memnuniyetle eşilik ediyoruz.",
    "about-us-sub-first-decription-3": "Büyük ölçekli kurumsal müşterilerin yanı sıra, çeşitli sektörlerden küçük ve orta ölçekli kurumlar da bulunmaktadır. Derinlemesine uzmanlığımız, yenilikçilik ruhumuz ve yıllar içinde geliştirdiğimiz iş ortaklıkları ve hizmet sağlayıcılardan oluşan ağımız sayesinde müşterilerimizin çeşitli isteklerine yanıt verebiliyoruz..",
    "about-us-sub-second-title": "Bizi Farklı Kılan Şey",
    "about-us-sub-second-decription": "Destek ve yardım masamız esnekliğinin yanı sıra hedefe yönelik zaman tasaruflu çözüm yöntemleriyle öne çıkmaktadır. İş saatleri dışında bile, yerinde, telefonla, e-posta yoluyla veya uzaktan bakım yazılımı kullanarak sizi destekliyoruz.",
    "about-us-sub-third-title": "Ve Son Olarak",
    "about-us-sub-third-decription": "İşler gelir gider - hepimiz insanız. Ve müşteri olarak insan, eylemlerimizin merkezinde yer alır. NGSI, iletişimin her düzeyinde kişisel ve açık bir iletişimi temsil eder. Tutkumuz, tüm iş ortaklarımıza adil, şeffaf ve empatiyle yaklaşmaktır.",
    "contact-down-email": "E-Posta",
    "contact-down-office": "Ofis",
    "contact-down-phone": "Telefon",
    "contact-down-email-des": "Ekibimiz yardımcı olmak için burada.",
    "contact-down-office-des": "Merkez ofisimizde bizi ziyaret edin.",
    "contact-down-phone-des": "Pazartesi-Cuma günleri 8:00-17:00 arasında telefonla ulaşabilirsiniz.",
    "modal-close":"kapat",
    "mobil-about-us-decription":"NGSI hakkında bilgiler",
    "mobil-solutions-description":"Bilişim sektörüne yönelik çözümler",
    "mobil-contact-decription":"NGSI hakkında daha fazla öğren",
    "cookie-title": "Çerez Bildirimi",
    "cookie-description": "En iyi hizmet için çerezleri kullanıyoruz. Bu web sitesini kullanarak çerez kullanımını kabul etmiş olursunuz.",
    "cookie-info-button": "Daha Fazla Bilgi",
    "cookie-accept-button": "Tamam - Kabul Ediyorum!",
    "mix-contact-title":"Bizimle İletişime Geçin",
    "mix-contact-1":"Sorularınızı sormaktan veya ihtiyaçlarınızı bildirmekten çekinmeden",
    "mix-contact-2":"iletişim sayfamıza",
    "mix-contact-3":"ilerleyin. Size yardımcı olmaktan mutluluk duyarız.",
    "mix-service-title":"Hizmetlerimizi denemekten çekinmeyin.",
    "mix-service-description":"Güçlü çözümler sunan hizmetlerimizi keşfedin."

}